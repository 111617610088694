@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spin-clockwise {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bubble-popping {
  33% {
    background-size: calc(100% / 3) 0, calc(100% / 3) 100%, calc(100% / 3) 100%;
  }

  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0, calc(100% / 3) 100%;
  }

  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0;
  }
}