@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use 'variables' as vars;
@use 'animations';
@include spb.set-typography;

// Remove annoying 5px margin
body, html {
  margin: 0;
  width: 100%;
  height: 100%;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}

/**
All icons must me centred instead of appearing a bit to the left in their "boxes"
 */
button {
  .mat-icon {
    margin: 0 !important;
  }
}

/*
Table styles
*/
table {
  caption {
    display: none;
  }

  th {
    color: rgb(0 0 0 / 54%) !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    white-space: nowrap !important;
  }

  th.align-header-right {
    .mat-sort-header-container {
      justify-content: flex-end !important;
    }
  }

  td.align-cell-right {
    text-align: right;
    padding-right: 34px; // Original is 16px + 18px more (sorting size)
  }

  .no-data {
    color: vars.$text-label-color;
    font-size: 24px;
    padding: 25px;
    text-align: center;
  }

  .sub-text {
    color: vars.$text-sub-value-color;
    font-size: 15px;
  }
}

/*
Custom loaders
 */
.loader-bubbles {
  width: 60px;
  aspect-ratio: 4;

  --popping: no-repeat radial-gradient(circle closest-side, #{vars.$form-icon-action-color} 90%, #0000);

  background: var(--popping) 0 50%,
  var(--popping) 50% 50%,
  var(--popping) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: bubble-popping 1s infinite linear;
}

/*
Mat-select styles (multi-line options)
 */
mat-select-trigger,
mat-option {
  div.multi-line {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    .empty {
      color: vars.$text-label-color;
    }

    .sub-value {
      color: vars.$text-sub-value-color;
      font-size: 16px;
      margin-top: -4px;
    }
  }
}

/*
Dialog common styles
 */
.dialog-content-holder {
  // Make dialog holder to be a flex-column container
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 24px;
  overflow: auto;
}

.dialog-content-holder,
.dialog-actions-holder {
  background-color: white;

  .dialog-title {
    margin-bottom: 15px;
    font-size: 20px;
    color: vars.$text-label-color;
  }

  mat-label,
  mat-form-field {
    color: vars.$form-label-color;
  }

  mat-datepicker-toggle {
    color: vars.$form-icon-action-color;
  }

  input {
    color: vars.$form-value-color;
  }
}

// Make dialog buttons to appear on the right side
.dialog-actions-holder {
  display: flex !important;
  margin-left: auto !important;

  button + spb-loading-button {
    margin-left: 8px;
  }
}
